import { styled } from '@mui/material/styles';
import { Checkbox } from '@mui/material';

export const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'isCustom' && prop !== 'customColor',
})<{ isCustom?: boolean; customColor?: string }>(({ theme, isCustom, customColor }) => ({
  '&.MuiCheckbox-root': {
    color: 'blueDodger',
  },
  '&.Mui-checked': {
    color: customColor || theme.palette.blueDodger,
  },
  '& svg': { fontSize: isCustom ? 28 : 24, verticalAlign: 'middle' },
  '&:hover': { backgroundColor: 'transparent' },
  '& .MuiTouchRipple-root': { display: 'none' },
  transition: 'none',
  padding: '0 0.5rem 0 0',
}));
