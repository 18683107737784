import { Box, SxProps, Typography } from '@mui/material';
import { TagButton } from 'app/components/FormElements/TagButton';
import { useController, useFormContext } from 'react-hook-form';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { RolledWrapper } from 'app/components/Wrappers/RolledWrapper';
import { useStatus } from 'hooks/useStatus';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';

interface OwnProps {
  name: string;
  description?: string;
  label: string;
  tags: { icon?: ReactNode; label: string; value: string | number; color?: string }[];
  disabledCheck?: (value: string | number) => boolean;
  onChange?: (name: string, data: string[]) => void;
  isRolled?: boolean;
  sx?: SxProps;
  tagsWrapperSx?: SxProps;
  rolledWrapperSx?: SxProps;
}

export const TagSelector = ({
  name,
  label,
  tags,
  description,
  disabledCheck,
  onChange,
  isRolled = false,
  tagsWrapperSx,
  rolledWrapperSx,
  sx,
}: OwnProps) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  const { isReadOnly } = useStatus();

  const {
    field: { value: selectedTags },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  const handleTagClick = (tagValue: string | number) => {
    const isAlreadySelected = selectedTags.includes(tagValue);

    const updatedValues = isAlreadySelected
      ? selectedTags.filter((v: string | number) => v !== tagValue)
      : [...selectedTags, tagValue];

    if (onChange) {
      onChange(name, updatedValues);
    }

    setValue(name, updatedValues, { shouldValidate: true });
  };

  return (
    <RolledWrapper
      label={label}
      name={name}
      description={description}
      isRolled={isRolled}
      sx={sx}
      rolledWrapperSx={rolledWrapperSx}
    >
      {tags.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
            ...tagsWrapperSx,
          }}
        >
          {tags.map((tag, tagIndex) => (
            <TagButton
              key={tagIndex}
              disabled={(disabledCheck ? disabledCheck(tag.value) : false) || isReadOnly}
              label={t(tag.label)}
              value={tag.value}
              icon={tag.icon}
              color={tag.color}
              handleTagClick={handleTagClick}
              isSelected={selectedTags.includes(tag.value)}
            />
          ))}
        </Box>
      ) : (
        <Typography sx={{ color: 'comet' }}>{t('noAvailableOptions')}</Typography>
      )}
      <CustomErrorMessage errors={errors} name={name} />
    </RolledWrapper>
  );
};
