import { ChangeEvent } from 'react';
import { FormControlLabel, SxProps } from '@mui/material';
import { useController } from 'react-hook-form';
import { StyledCheckbox } from 'app/components/FormElements/StyledCheckbox';

interface OwnProps {
  name: string;
  label?: string | null;
  sx?: SxProps;
  onChange?: (event: any) => void;
  checked?: boolean;
  isCustom?: boolean;
  disabled?: boolean;
}

export const MuiCheckbox = ({
  name,
  label,
  sx,
  onChange,
  checked,
  isCustom,
  disabled,
}: OwnProps) => {
  const { field } = useController({
    name,
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    } else {
      field.onChange(e);
    }
  };

  const isChecked = checked !== undefined ? checked : field.value;

  return (
    <FormControlLabel
      control={
        <StyledCheckbox
          checked={isChecked}
          {...field}
          onChange={handleOnChange}
          isCustom={isCustom}
          disabled={disabled}
        />
      }
      name={name}
      label={label}
      sx={{
        ...sx,
        wordBreak: 'break-word',
        marginLeft: 0,
        marginRight: 0,
        '& .MuiFormControlLabel-label': {
          lineHeight: isCustom ? 1 : 1.5,
          color: isChecked ? 'blueDodger' : 'comet',
          fontSize: '14px',
        },
      }}
    />
  );
};
