import { MenuItem, Select, SxProps, Typography, Box, SelectChangeEvent } from '@mui/material';
import { ReactElement, ReactNode, useState } from 'react';
import { RotatingArrow } from 'app/components/StyledComponents/RotatingArrow';
import { useTranslation } from 'react-i18next';
import { getLabelForValue } from 'utils/data-processors/getLabelForValue';

export interface CustomSelectOption {
  icon?: ReactNode;
  label: string;
  value?: string | number;
  disabled?: boolean;
  colors?: {
    menuItemColor?: string;
    firstColor: string;
    secondColor: string;
  };
  data?: any;
}

interface OwnProps {
  field?: any;
  options: CustomSelectOption[];
  sx?: SxProps;
  startAdornment?: ReactElement;
  translateLabels?: boolean;
  value?: any;
  onChange?: (event: SelectChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  menuItemColor?: string;
  fullWidth?: boolean;
}

export const CustomSelect = ({
  field = {},
  options,
  startAdornment,
  translateLabels = true,
  value,
  onChange,
  disabled,
  sx,
  fullWidth,
}: OwnProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const optionsToRender = options.map(({ value, label, disabled, colors, icon }, index) => (
    <MenuItem
      key={index}
      value={value}
      disabled={disabled}
      sx={{
        color: colors?.menuItemColor || colors?.firstColor,
        gap: '8px',
        '& svg ': {
          width: 20,
          height: 20,
        },
      }}
    >
      {icon}
      {translateLabels ? t(label) : label}
    </MenuItem>
  ));

  const getColorsForValue = (value: string | number, options: CustomSelectOption[]) => {
    const selectedOption = options.find((option) => option.value === value);
    return selectedOption?.colors;
  };

  const getIconForValue = (value: string | number, options: CustomSelectOption[]) => {
    const selectedOption = options.find((option) => option.value === value);
    return selectedOption?.icon;
  };

  return (
    <Select
      fullWidth={fullWidth}
      value={value}
      onChange={onChange}
      {...field}
      displayEmpty={true}
      disabled={disabled}
      IconComponent={() => null}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      renderValue={(value: any) => {
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '8px',
                alignItems: 'center',
                color: getColorsForValue(value, options)?.firstColor,
                '& svg ': {
                  width: 20,
                  height: 20,
                },
              }}
            >
              {startAdornment}
              {getIconForValue(value, options)}
              <Typography
                sx={{
                  flex: 1,
                  color: getColorsForValue(value, options)?.firstColor,
                }}
              >
                {getLabelForValue(value, options, translateLabels, t)}
              </Typography>
            </Box>
            <RotatingArrow
              open={isOpen}
              sx={{
                position: 'absolute',
                top: '8px',
                right: '6px',
                color: getColorsForValue(value, options)?.firstColor,
              }}
              isMargin={false}
            />
          </>
        );
      }}
      sx={{
        maxWidth: { xs: '100%', sm: '180px' },
        width: '100%',
        '& .MuiSelect-select': {
          paddingTop: '9px',
          paddingBottom: '9px',
        },
        backgroundColor: getColorsForValue(value, options)?.secondColor,
        ...sx,
      }}
    >
      {optionsToRender}
    </Select>
  );
};
