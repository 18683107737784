import { Typography, ButtonBase, Box } from '@mui/material';
import { ReactNode } from 'react';

interface OwnProps {
  label: string;
  value: string | number;
  icon: ReactNode;
  handleTagClick: (value: string | number) => void;
  disabled?: boolean;
  color?: string;
  isSelected: boolean;
}

export const TagButton = ({
  label,
  handleTagClick,
  value,
  icon,
  disabled,
  color = 'blueDodger',
  isSelected,
}: OwnProps) => {
  return (
    <ButtonBase
      onClick={() => handleTagClick(value)}
      disabled={disabled}
      sx={{
        minWidth: '64px',
        border: '1px solid',
        borderColor: color,
        borderRadius: icon ? '5px' : '32px',
        gap: '8px',
        alignItems: 'center',
        ...(isSelected && {
          color: 'white',
          backgroundColor: color,
          border: '1px solid',
        }),

        minHeight: '24px',
        fontSize: '12px',
        padding: icon ? '4.5px 16px' : '0px 8px',
        ...(disabled && {
          borderColor: 'disabled2',
        }),
        ...(disabled &&
          isSelected && {
            backgroundColor: 'disabled',
          }),
      }}
    >
      {icon && (
        <Box sx={{ display: 'flex', color: isSelected ? 'brandWhiteDark' : color }}>{icon}</Box>
      )}
      <Typography
        sx={{
          fontSize: '12px',
          color: isSelected ? 'white' : color,
          ...(disabled && {
            color: 'disabled2',
          }),
        }}
      >
        {label}
      </Typography>
    </ButtonBase>
  );
};
