import { NewBookingModal } from 'app/components/NewBookingModal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import notesActions from 'redux/actions/notes';
import NotesDrawer from 'app/components/Notes/NotesDrawer';

export const GlobalModals = () => {
  const dispatch = useAppDispatch();
  const isNotesDrawerOpen = useAppSelector((state) => state.notes.isDrawerOpen);

  const handleSetIsNotesDrawerOpen = (isOpen: boolean) => {
    dispatch(notesActions.setIsDrawerOpen(isOpen));
  };

  return (
    <>
      <NewBookingModal />
      <NotesDrawer open={isNotesDrawerOpen} onClose={() => handleSetIsNotesDrawerOpen(false)} />
    </>
  );
};
