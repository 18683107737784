import { FormControl } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { useController } from 'react-hook-form';
import { CountryCode } from 'libphonenumber-js';
import { CustomLabel } from 'app/components/CustomLabel';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';
import { MutableRefObject } from 'react';

interface OwnProps {
  name: string;
  defaultLabel?: string;
  label?: string;
  isError?: boolean;
  handleInputChange?: () => void;
  defaultCountry?: CountryCode;
  inputRef?: MutableRefObject<any>;
  disabled?: boolean;
}

export const MuiPhoneInput = ({
  name,
  label,
  defaultLabel,
  isError,
  handleInputChange,
  defaultCountry = 'GB',
  inputRef,
  disabled,
}: OwnProps) => {
  const {
    field,
    formState: { errors },
  } = useController({
    name,
    rules: { required: false },
  });

  const handleOnChange = (value: string) => {
    if (handleInputChange) {
      handleInputChange();
    }
    field.onChange(value);
  };

  return (
    <FormControl fullWidth>
      <CustomLabel label={label} name={name} />
      <MuiTelInput
        {...field}
        disabled={disabled}
        label={defaultLabel}
        error={isError || !!errors[name]}
        defaultCountry={defaultCountry}
        onChange={handleOnChange}
        ref={inputRef}
        sx={{
          '& .MuiInputBase-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
          input: {
            paddingTop: '10px !important',
            paddingBottom: '10px !important',
          },
        }}
      />
      <CustomErrorMessage errors={errors} name={name} />
    </FormControl>
  );
};
