import { TextField, FormControl, Box } from '@mui/material';
import { CustomLabel } from 'app/components/CustomLabel';
import { CommentDate } from 'app/components/NewBookingModal/_components/ReservationComments/_components/CommentDate';

interface OwnProps {
  name?: string;
  label?: string;
  description?: string | null;
  disabled?: boolean;
  value?: string;
  changed?: number;
}

export const StyledTextarea = ({ name, label, disabled, value, changed }: OwnProps) => {
  return (
    <FormControl>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <CustomLabel label={label} name={name} />
        {changed && <CommentDate changed={changed} />}
      </Box>
      <TextField
        name={name}
        disabled={disabled}
        multiline
        rows={3}
        value={value}
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: 'zircon',
            padding: '10px 12px',
          },
        }}
      />
    </FormControl>
  );
};
